import React, { useEffect, useState } from "react";
import NewsletterItem from "./newsletterItem";
import axios from "axios";
/* Newsletter List
============================================================================= */

const NewsletterList = ({ podcasts }) => {
	// Config
	const [email, setEmail] = useState("");

	// On Submit
	const onSubmit = async (e) => {
		e.preventDefault();
		const encode = (data) => {
			return Object.keys(data)
				.map(
					(key) =>
						encodeURIComponent(key) +
						"=" +
						encodeURIComponent(data[key])
				)
				.join("&");
		};
		await axios
			.post(
				"/",
				encode({
					email,
					"form-name": "newsletter",
				}),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then(() => {
				setEmail("");
				alert("Subscribed!");
			});
	};
	// Render
	return (
		<section
			role="region"
			aria-label="Newsletter"
			className="w-full padding-top padding-bottom horizontal-padding"
		>
			<div className="container mx-auto grid grid-cols-1 gap-12">
				<div className="mx-auto">
					<h2
						role="heading"
						className="font-display text-5xl text-left text-black lg:pb-0 pb-4"
					>
						Subscribe to the Litmus Media Newsletter
					</h2>
					<p className="font-body text-left text-lg tracking-wide leading-relaxed pt-2 pb-10 text-black lg:w-1/2 hidden">
						Receive articles about new and upcoming Australian
						underground music with insight into the future sounds of
						Australia.
					</p>
					<form className="w-4/5 flex flex-row items-center justify-start">
						<input
							type="email"
							placeholder="Enter your Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="w-full rounded-md py-3 px-4 font-body shadow-lg bg-black text-white"
						/>
						<button
							type="submit"
							onClick={onSubmit}
							className="rounded-md bg-black px-8 py-2 ml-4 text-white font-display text-2xl shadow-lg"
						>
							Join
						</button>
					</form>
				</div>
				<div />
			</div>
		</section>
	);
};

/* Export
============================================================================= */

export default NewsletterList;
