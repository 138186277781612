import React, { useEffect } from "react";
import { Link } from "gatsby";
import { SEO } from "components";
import { NewsletterList } from "components/newsletters";
import { PageHeading } from "components/layout";
import useMenu from "state/useMenu";
/* Newsletters Page
============================================================================= */

const NewslettersPage = ({ data }) => {
	// Config
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	return (
		<>
			<SEO title="Newsletters" />
			<NewsletterList podcasts={data.allSanityPodcast.nodes} />
		</>
	);

	// Render
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	{
		allSanityPodcast {
			nodes {
				name
				podcast_sponsors {
					name
					website
					description
					image {
						alt
						image {
							asset {
								localFile {
									childImageSharp {
										fluid {
											src
											srcSet
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

/* Export
============================================================================= */

export default NewslettersPage;
